/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { OutputGetAllRequestsDto } from '../models/output-get-all-requests-dto';
import { OutputGetOrderReturnDetailsDto } from '../models/output-get-order-return-details-dto';
import { OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto } from '../models/output-get-paged-order-returns-dto-list-output-paged-response-dto';
import { OutputGetRequestDetailsDto } from '../models/output-get-request-details-dto';
import { trackingManagementChangeOrderReturnStatus } from '../fn/tracking-management/tracking-management-change-order-return-status';
import { TrackingManagementChangeOrderReturnStatus$Params } from '../fn/tracking-management/tracking-management-change-order-return-status';
import { trackingManagementChangeOrderReturnStatus$Plain } from '../fn/tracking-management/tracking-management-change-order-return-status-plain';
import { TrackingManagementChangeOrderReturnStatus$Plain$Params } from '../fn/tracking-management/tracking-management-change-order-return-status-plain';
import { trackingManagementGetOrderReturnDetails } from '../fn/tracking-management/tracking-management-get-order-return-details';
import { TrackingManagementGetOrderReturnDetails$Params } from '../fn/tracking-management/tracking-management-get-order-return-details';
import { trackingManagementGetOrderReturnDetails$Plain } from '../fn/tracking-management/tracking-management-get-order-return-details-plain';
import { TrackingManagementGetOrderReturnDetails$Plain$Params } from '../fn/tracking-management/tracking-management-get-order-return-details-plain';
import { trackingManagementGetPagedOrderReturns } from '../fn/tracking-management/tracking-management-get-paged-order-returns';
import { TrackingManagementGetPagedOrderReturns$Params } from '../fn/tracking-management/tracking-management-get-paged-order-returns';
import { trackingManagementGetPagedOrderReturns$Plain } from '../fn/tracking-management/tracking-management-get-paged-order-returns-plain';
import { TrackingManagementGetPagedOrderReturns$Plain$Params } from '../fn/tracking-management/tracking-management-get-paged-order-returns-plain';
import { trackingManagementGetRequestDetails } from '../fn/tracking-management/tracking-management-get-request-details';
import { TrackingManagementGetRequestDetails$Params } from '../fn/tracking-management/tracking-management-get-request-details';
import { trackingManagementGetRequestDetails$Plain } from '../fn/tracking-management/tracking-management-get-request-details-plain';
import { TrackingManagementGetRequestDetails$Plain$Params } from '../fn/tracking-management/tracking-management-get-request-details-plain';
import { trackingManagementGetRequests } from '../fn/tracking-management/tracking-management-get-requests';
import { TrackingManagementGetRequests$Params } from '../fn/tracking-management/tracking-management-get-requests';
import { trackingManagementGetRequests$Plain } from '../fn/tracking-management/tracking-management-get-requests-plain';
import { TrackingManagementGetRequests$Plain$Params } from '../fn/tracking-management/tracking-management-get-requests-plain';
import { trackingManagementUpdateRequestStatues } from '../fn/tracking-management/tracking-management-update-request-statues';
import { TrackingManagementUpdateRequestStatues$Params } from '../fn/tracking-management/tracking-management-update-request-statues';
import { trackingManagementUpdateRequestStatues$Plain } from '../fn/tracking-management/tracking-management-update-request-statues-plain';
import { TrackingManagementUpdateRequestStatues$Plain$Params } from '../fn/tracking-management/tracking-management-update-request-statues-plain';

@Injectable({ providedIn: 'root' })
export class TrackingManagementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `trackingManagementGetRequests()` */
  static readonly TrackingManagementGetRequestsPath = '/api/admin/TrackingManagement/GetRequests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementGetRequests$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetRequests$Plain$Response(params?: TrackingManagementGetRequests$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllRequestsDto>>> {
    return trackingManagementGetRequests$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `trackingManagementGetRequests$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetRequests$Plain(params?: TrackingManagementGetRequests$Plain$Params, context?: HttpContext): Observable<Array<OutputGetAllRequestsDto>> {
    return this.trackingManagementGetRequests$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllRequestsDto>>): Array<OutputGetAllRequestsDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementGetRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetRequests$Response(params?: TrackingManagementGetRequests$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OutputGetAllRequestsDto>>> {
    return trackingManagementGetRequests(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `trackingManagementGetRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetRequests(params?: TrackingManagementGetRequests$Params, context?: HttpContext): Observable<Array<OutputGetAllRequestsDto>> {
    return this.trackingManagementGetRequests$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllRequestsDto>>): Array<OutputGetAllRequestsDto> => r.body)
    );
  }

  /** Path part for operation `trackingManagementGetRequestDetails()` */
  static readonly TrackingManagementGetRequestDetailsPath = '/api/admin/TrackingManagement/GetRequestDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementGetRequestDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetRequestDetails$Plain$Response(params?: TrackingManagementGetRequestDetails$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetRequestDetailsDto>> {
    return trackingManagementGetRequestDetails$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `trackingManagementGetRequestDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetRequestDetails$Plain(params?: TrackingManagementGetRequestDetails$Plain$Params, context?: HttpContext): Observable<OutputGetRequestDetailsDto> {
    return this.trackingManagementGetRequestDetails$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetRequestDetailsDto>): OutputGetRequestDetailsDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementGetRequestDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetRequestDetails$Response(params?: TrackingManagementGetRequestDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetRequestDetailsDto>> {
    return trackingManagementGetRequestDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `trackingManagementGetRequestDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetRequestDetails(params?: TrackingManagementGetRequestDetails$Params, context?: HttpContext): Observable<OutputGetRequestDetailsDto> {
    return this.trackingManagementGetRequestDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetRequestDetailsDto>): OutputGetRequestDetailsDto => r.body)
    );
  }

  /** Path part for operation `trackingManagementUpdateRequestStatues()` */
  static readonly TrackingManagementUpdateRequestStatuesPath = '/api/admin/TrackingManagement/UpdateRequestStatues';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementUpdateRequestStatues$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementUpdateRequestStatues$Plain$Response(params?: TrackingManagementUpdateRequestStatues$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return trackingManagementUpdateRequestStatues$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `trackingManagementUpdateRequestStatues$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementUpdateRequestStatues$Plain(params?: TrackingManagementUpdateRequestStatues$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.trackingManagementUpdateRequestStatues$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementUpdateRequestStatues()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementUpdateRequestStatues$Response(params?: TrackingManagementUpdateRequestStatues$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return trackingManagementUpdateRequestStatues(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `trackingManagementUpdateRequestStatues$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementUpdateRequestStatues(params?: TrackingManagementUpdateRequestStatues$Params, context?: HttpContext): Observable<boolean> {
    return this.trackingManagementUpdateRequestStatues$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `trackingManagementGetPagedOrderReturns()` */
  static readonly TrackingManagementGetPagedOrderReturnsPath = '/api/admin/TrackingManagement/GetPagedOrderReturns';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementGetPagedOrderReturns$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetPagedOrderReturns$Plain$Response(params?: TrackingManagementGetPagedOrderReturns$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto>> {
    return trackingManagementGetPagedOrderReturns$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `trackingManagementGetPagedOrderReturns$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetPagedOrderReturns$Plain(params?: TrackingManagementGetPagedOrderReturns$Plain$Params, context?: HttpContext): Observable<OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto> {
    return this.trackingManagementGetPagedOrderReturns$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto>): OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementGetPagedOrderReturns()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetPagedOrderReturns$Response(params?: TrackingManagementGetPagedOrderReturns$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto>> {
    return trackingManagementGetPagedOrderReturns(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `trackingManagementGetPagedOrderReturns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetPagedOrderReturns(params?: TrackingManagementGetPagedOrderReturns$Params, context?: HttpContext): Observable<OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto> {
    return this.trackingManagementGetPagedOrderReturns$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto>): OutputGetPagedOrderReturnsDtoListOutputPagedResponseDto => r.body)
    );
  }

  /** Path part for operation `trackingManagementGetOrderReturnDetails()` */
  static readonly TrackingManagementGetOrderReturnDetailsPath = '/api/admin/TrackingManagement/GetOrderReturnDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementGetOrderReturnDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetOrderReturnDetails$Plain$Response(params?: TrackingManagementGetOrderReturnDetails$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetOrderReturnDetailsDto>> {
    return trackingManagementGetOrderReturnDetails$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `trackingManagementGetOrderReturnDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetOrderReturnDetails$Plain(params?: TrackingManagementGetOrderReturnDetails$Plain$Params, context?: HttpContext): Observable<OutputGetOrderReturnDetailsDto> {
    return this.trackingManagementGetOrderReturnDetails$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetOrderReturnDetailsDto>): OutputGetOrderReturnDetailsDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementGetOrderReturnDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetOrderReturnDetails$Response(params?: TrackingManagementGetOrderReturnDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<OutputGetOrderReturnDetailsDto>> {
    return trackingManagementGetOrderReturnDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `trackingManagementGetOrderReturnDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementGetOrderReturnDetails(params?: TrackingManagementGetOrderReturnDetails$Params, context?: HttpContext): Observable<OutputGetOrderReturnDetailsDto> {
    return this.trackingManagementGetOrderReturnDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<OutputGetOrderReturnDetailsDto>): OutputGetOrderReturnDetailsDto => r.body)
    );
  }

  /** Path part for operation `trackingManagementChangeOrderReturnStatus()` */
  static readonly TrackingManagementChangeOrderReturnStatusPath = '/api/admin/TrackingManagement/ChangeOrderReturnStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementChangeOrderReturnStatus$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementChangeOrderReturnStatus$Plain$Response(params?: TrackingManagementChangeOrderReturnStatus$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return trackingManagementChangeOrderReturnStatus$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `trackingManagementChangeOrderReturnStatus$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementChangeOrderReturnStatus$Plain(params?: TrackingManagementChangeOrderReturnStatus$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.trackingManagementChangeOrderReturnStatus$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `trackingManagementChangeOrderReturnStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementChangeOrderReturnStatus$Response(params?: TrackingManagementChangeOrderReturnStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return trackingManagementChangeOrderReturnStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `trackingManagementChangeOrderReturnStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  trackingManagementChangeOrderReturnStatus(params?: TrackingManagementChangeOrderReturnStatus$Params, context?: HttpContext): Observable<boolean> {
    return this.trackingManagementChangeOrderReturnStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
